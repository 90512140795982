import React from "react"
import SEO from "../components/seo"
import Chapter5 from "src/views/Chapter5"
import OGImage from "../images/seo/glosy_pisarzy.jpg"

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Najwięksi polscy pisarze"
        description="Literaci i poeci współpracowali z Polskim Radiem, tworzyli dla niego, zyskiwali nową przestrzeń, w której mogła wybrzmieć ich twórczość."
        image={OGImage}
      />
      <Chapter5 />
    </>
  )
}

export default IndexPage
